<template>
    <div>
        <div class="ml-2">
            <h1 v-if="id" class="h2">
                Detalle de proveedor #{{ id }} /

                <strong class="text-primary">
                    {{ itemComponent.name }}
                </strong>
            </h1>
            <h1 v-else class="h2">Nuevo proveedor</h1>
        </div>

        <vs-navbar
            id="tabs-products"
            v-model="activeTab"
            center-collapsed
            not-line
            class="position-static"
        >
            <template #left>
                <vs-navbar-item id="home">
                    <router-link
                        to="/app/suppliers"
                        class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                    >
                        <span>🏠</span>
                    </router-link>
                </vs-navbar-item>

                <vs-navbar-item
                    v-if="id"
                    id="general"
                    :active="['general', 'home'].includes(activeTab)"
                    class="px-2 px-md-3"
                >
                    <div class="d-flex">
                        <span class="d-md-block d-none"> General </span>
                        <span>&nbsp;⚙</span>
                    </div>
                </vs-navbar-item>

                <vs-navbar-item
                    v-if="$enabledEcommerceOptions"
                    id="seo"
                    :active="activeTab === 'seo'"
                    class="px-2 px-md-3"
                >
                    <div class="d-flex">
                        <span class="d-md-block d-none"> SEO&nbsp; </span>
                        <span>&nbsp;🐱‍💻</span>
                    </div>
                </vs-navbar-item>
            </template>
            <template #right>
                <vs-switch
                    v-if="$ability.hasScope('supplier:Update') && id"
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                >
                    🖊
                </vs-switch>
            </template>
        </vs-navbar>

        <div v-show="['general', 'home'].includes(activeTab)">
            <div v-if="id" class="d-flex flex-wrap">
                <div
                    class="col-12 col-md-6 mt-5 d-flex justify-content-center align-items-center"
                >
                    <div v-if="$ability.hasScope('supplier:UpdateActive')">
                        <active-selector
                            v-model="itemComponent.active"
                            :enabled="enabledUpdate"
                            on-label="Publicado"
                            off-label="Oculto"
                            @change="validateToggleActive"
                        />
                    </div>
                </div>
                <div
                    class="col-12 col-md-6 mt-5 p-0 d-flex justify-content-around align-items-center"
                >
                    <small class="text-left">
                        📆💾 {{ itemComponent.createdAt | date }}
                    </small>
                    <small class="text-left">
                        📆✏ {{ itemComponent.updatedAt | date }}
                    </small>
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.name"
                        border
                        shadow
                        :state="stateEnabled"
                        icon
                        label="Nombre"
                        class="opacity-false"
                        :disabled="!enabledUpdate"
                        @blur="setSlug"
                    >
                        <template
                            v-if="!isValidName & introValidators.name"
                            #message-danger
                        >
                            Nombre de proveedor invalido
                        </template>
                    </vs-input>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.slug"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Slug"
                        class="opacity-false"
                        :disabled="!enabledUpdate"
                        @blur="introValidators.slug = true"
                    >
                        <template
                            v-if="!isValidSlug && introValidators.slug"
                            #message-danger
                        >
                            Slug invalido
                        </template>
                    </vs-input>
                </div>
            </div>

            <div class="d-flex flex-wrap mt-3">
                <div class="col-12 mx-auto px-3">
                    <label for="description">Contenido</label>
                    <markdown-editor
                        v-model="itemComponent.description"
                        class="mt-2"
                        :enabled="enabledUpdate"
                    />
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.shortDescription"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Descripción"
                        class="opacity-false"
                        :disabled="!enabledUpdate"
                    />
                </div>

                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.phone"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Celular de contacto"
                        type="number"
                        :disabled="!enabledUpdate"
                        @blur="introValidators.phone = true"
                    >
                        <template
                            v-if="!isValidPhone & introValidators.phone"
                            #message-danger
                        >
                            Celular invalido
                        </template>
                    </vs-input>
                </div>

                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.tin"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Nit"
                        :disabled="!enabledUpdate"
                    />
                </div>
                <div
                    v-if="$enabledEcommerceOptions"
                    class="col-12 col-md-6 mt-5"
                >
                    <keywords-selector
                        v-model="itemComponent.keywords"
                        label="Palabras clave"
                        :enabled="enabledUpdate"
                    />
                </div>
            </div>

            <div class="d-flex flex-wrap mt-4">
                <div
                    v-if="enabledUpdate"
                    class="col-12 d-flex justify-content-center"
                >
                    <vs-button
                        v-if="!id"
                        size="large"
                        @click="createSupplierComponent"
                    >
                        Crear proveedor
                    </vs-button>
                    <vs-button v-else @click="updateSupplierComponent">
                        Actualizar proveedor
                    </vs-button>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'seo'" id="seoTab">
            <seo-analytic
                :meta-description="itemComponent.shortDescription"
                :url="`/suppliers/${itemComponent.slug}`"
                :title="itemComponent.name"
                :keywords="itemComponent.keywords"
                :description="`# ${itemComponent.name} 
                ${itemComponent.description}`"
            />
        </div>

        <vs-dialog
            v-model="openActiveModal"
            auto-width
            @close="closeActiveModal"
        >
            <template #header>
                <h2>Quieres habilitar la proveedor ?</h2>
            </template>
            <div class="my-3 mx-2">
                <div class="col-10 mx-auto">
                    <span>
                        Recuerda llenar la información del proveedor, de esta
                        manera será encontrado más fácil. Selecciona el peso de
                        ordenamiento, la proveedor con peso 1 será el primero en
                        aparecer en el listado.
                    </span>
                </div>

                <vs-input
                    v-model="itemComponent.weight"
                    class="col-8 mx-auto mt-4"
                    label="Peso"
                    state="primary"
                    type="number"
                    step="1"
                    min="1"
                />
            </div>
            <template #footer>
                <div class="con-footer d-flex justify-content-center my-2">
                    <vs-button @click="updateSupplierActiveComponent">
                        Activar proveedor
                    </vs-button>
                </div>
            </template>
        </vs-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { phoneRegex, slugRegex } from "@/common/lib/regex";
import { validateConfirmationMessage } from "@/common/lib/transformers";

import ActiveSelector from "@/components/utils/ActiveSelector.vue";
import KeywordsSelector from "@/components/utils/KeywordsSelector.vue";
import MarkdownEditor from "@/components/utils/MarkdownEditor.vue";
import SeoAnalytic from "@/components/utils/SeoAnalytic.vue";

export default {
    name: "SupplierDetail",
    components: {
        SeoAnalytic,
        ActiveSelector,
        MarkdownEditor,
        KeywordsSelector
    },
    data: () => ({
        activeTab: "general",
        id: "",

        isLoading: false,
        enabledUpdate: false,
        loaderInstance: null,
        loadedAllData: false,
        openActiveModal: false,
        itemComponent: {
            id: "",
            name: "",
            slug: "",
            tin: "",
            description: "",
            active: false,
            shortDescription: "",
            phone: "",
            address: "",
            keywords: [],
            weight: 1000
        },
        introValidators: {
            phone: false,
            name: false,
            slug: false
        }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        isValidPhone() {
            return phoneRegex.test(this.itemComponent.phone);
        },
        isValidName() {
            return this.itemComponent.name.length;
        },
        isValidSlug() {
            return slugRegex.test(this.itemComponent.slug);
        },
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if ("id" in this.$route.params) {
            if (!(await verifyPageScope("supplier:Get", "/app/suppliers")))
                return;
            const id = this.$route.params.id;
            this.id = id;
            await this.getBaseItem(id);
        } else if ("idClone" in this.$route.params) {
            await verifyPageScope("supplier:Create", "/app/suppliers");
            this.id = 0;
            this.enabledUpdate = true;
            const idTmp = this.$route.params.idClone;
            await this.getBaseItem(idTmp);
            this.itemComponent = {
                ...this.itemComponent,
                name: `${this.itemComponent.name} CLONE`,
                slug: `${this.itemComponent.slug}-clone`,
                active: false
            };
            this.$swal.fire({
                background: this.backgroundColor,
                title: "Estas clonando un proveedor",
                icon: "info",
                showCloseButton: true
            });
        } else {
            await verifyPageScope("supplier:Create", "/app/suppliers");
            this.enabledUpdate = true;
            this.loadedAllData = true;
        }
    },
    methods: {
        ...mapActions("suppliers", [
            "getSupplierById",
            "updateSupplier",
            "deleteSupplier",
            "createSupplier",
            "updateSupplierActive"
        ]),
        closeActiveModal() {
            this.itemComponent.active = false;
        },
        validateToggleActive() {
            if (!this.itemComponent.active) {
                return this.updateSupplierActiveComponent();
            }
            this.openActiveModal = true;
        },
        async getBaseItem(id) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                const data = await this.getSupplierById({ id });
                this.itemComponent = {
                    ...data
                };
                this.loadedAllData = true;
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/suppliers");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateSupplierActiveComponent() {
            this.openActiveModal = false;
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                await this.updateSupplierActive(this.itemComponent);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            }
        },
        async updateSupplierComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                await this.updateSupplier(this.getDataSend());
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async deleteSupplierComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: "Estas seguro deseas eliminar el proveedor",
                text: `Las proveedors solo deben eliminarse en caso de que se esten haciendo pruebas, eres consiente de lo que estas haciendo ?. Escribe 'Si' para eliminar `,
                icon: "warning",
                input: "text",
                confirmButtonColor: "#ee3f59",
                cancelButtonColor: "#253C99",
                showCancelButton: true
            });
            if (
                dialogResult.isConfirmed &&
                validateConfirmationMessage(dialogResult.value)
            ) {
                this.isLoading = true;
                this.loaderInstance = this.$vs.loading({ type: "corners" });
                try {
                    await this.deleteSupplier({
                        id: this.id
                    });
                    this.$router.push("/app/suppliers");
                } catch (error) {
                    this.loaderInstance.close();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                } finally {
                    this.loaderInstance.close();
                    this.isLoading = false;
                }
            }
        },
        async createSupplierComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                const data = await this.createSupplier(this.getDataSend());
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();

                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Creación exitosa",
                    text: `Id: ${data.id}`,
                    icon: "success",
                    showDenyButton: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#253C99",
                    denyButtonColor: "#f07325",
                    confirmButtonText: "Ver detalle",
                    denyButtonText: "Ir a lista de proveedores",
                    cancelButtonText: "Nuevo proveedor"
                });
                if (dialogResult.isConfirmed) {
                    this.id = data.id;
                    this.itemComponent = { ...data, ...this.itemComponent };
                    this.$router.push(`/app/suppliers/detail/${this.id}`);
                }
                if (dialogResult.isDenied) {
                    this.$router.push("/app/suppliers/");
                }
                if (dialogResult.isDismissed) {
                    this.introValidators = {
                        phone: false,
                        name: false,
                        slug: false
                    };
                    this.itemComponent = {
                        id: "",
                        name: "",
                        slug: "",
                        active: false,
                        description: "",
                        shortDescription: "",
                        phone: "",
                        weight: 1000
                    };
                }
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/suppliers");
                }
            } finally {
                this.isLoading = false;
            }
        },
        async setSlug() {
            this.introValidators.name = true;
            if (
                this.enabledUpdate &&
                this.isValidName &&
                !this.itemComponent.active
            ) {
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Quieres cambiar el slug ?",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#d33ddd",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: "Si",
                    cancelButtonText: "No"
                });
                if (dialogResult.isConfirmed) {
                    this.itemComponent.slug = this.itemComponent.name
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLowerCase()
                        .replaceAll(".", "")
                        .replaceAll(",", "")
                        .replaceAll(" ", "-");
                }
            }
        },
        validateData() {
            this.introValidators = {
                slug: true,
                phone: true,
                name: true
            };

            const toComplete = [];
            if (!this.isValidSlug) {
                toComplete.push("Slug");
            }

            if (!this.isValidPhone) {
                toComplete.push("Telefono");
            }

            if (toComplete.length) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Proveedor invalido",
                    text: `Revisa por favor que todo este completo. Valida los siguientes campos: ${toComplete.join(
                        ", "
                    )}`,
                    icon: "info"
                });
                return false;
            }
            return true;
        },
        getDataSend() {
            return {
                ...this.itemComponent,
                id: this.id
            };
        }
    }
};
</script>
